import { Builder } from '@builder.io/react'
import {
  CLEANSER_PRODUCT,
  DAY_EYE_CREAM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  EYE_CREAM_PRODUCT,
  HYDRATION_BOOST_DAY_MOISTURIZER,
  NIGHT_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  SERUM_PRODUCT,
  SYSTEM_PRODUCT,
  SPOT_TREATMENT_SERUM,
  MICELLAR_WATER,
  PILLOW_CASE
} from '../../../../constants/products'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import React from 'react'
import ProductDetailsBasedOnUserAuthentication from '../../../shared/product/product-details-based-on-user-authentication'

const NEVER = 'never'
const USER_IS_AUTHENTICATED = 'user is authenticated'
const USER_IS_NOT_AUTHENTICATED = 'user is not authenticated'

const SHOW_RADIO_BUTTON = 'Show radio button'
const FORCE_ONE_TIME = 'Force one-time'
const FORCE_SUBSCRIPTION = 'Force Subscription'

export const ProductDetailsForBuilder = ({
  hideWhen,
  bulletPointsForNonAuthenticatedUser,
  buttonTextForAuthenticatedUser,
  buttonTextForUnauthenticatedUser,
  forceOneTimeOrSubscription,
  ...props
}) => {
  return (
    <ProvenResponsiveContainer>
      <ProductDetailsBasedOnUserAuthentication
        buttonTextForAuthenticatedUser={
          buttonTextForAuthenticatedUser ? buttonTextForAuthenticatedUser : undefined
        }
        buttonTextForUnauthenticatedUser={
          buttonTextForUnauthenticatedUser ? buttonTextForUnauthenticatedUser : undefined
        }
        bulletPointsForNonAuthenticatedUser={(bulletPointsForNonAuthenticatedUser || []).map(
          bp => ({ ...bp, text: <span dangerouslySetInnerHTML={{ __html: bp.text }}></span> })
        )}
        hideWhenIsAuthenticated={hideWhen === USER_IS_AUTHENTICATED}
        hideWhenIsNotAuthenticated={hideWhen === USER_IS_NOT_AUTHENTICATED}
        forceOneTimeAction={
          forceOneTimeOrSubscription && forceOneTimeOrSubscription === FORCE_ONE_TIME
        }
        forceSubscriptionAction={
          forceOneTimeOrSubscription && forceOneTimeOrSubscription === FORCE_SUBSCRIPTION
        }
        {...props}
      />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(ProductDetailsForBuilder, {
  name: 'Product Details',
  inputs: [
    {
      name: 'product',
      type: 'text',
      enum: [
        SYSTEM_PRODUCT,
        DAY_MOISTURIZER_PRODUCT,
        NIGHT_CREAM_PRODUCT,
        CLEANSER_PRODUCT,
        EYE_CREAM_PRODUCT,
        NIGHT_EYE_CREAM_PRODUCT,
        DAY_EYE_CREAM_PRODUCT,
        SERUM_PRODUCT,
        HYDRATION_BOOST_DAY_MOISTURIZER,
        SPOT_TREATMENT_SERUM,
        MICELLAR_WATER,
        PILLOW_CASE
      ],
      required: true
    },
    { name: 'subtitle', type: 'text' },
    { name: 'showSubtitleWithNameOfAuthenticatedUser', type: 'boolean', defaultValue: false },
    { name: 'title', type: 'text' },
    { name: 'description', type: 'text' },
    { name: 'size', type: 'text' },
    {
      name: 'bulletPointsForNonAuthenticatedUser',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'icon',
          type: 'file'
        },
        {
          name: 'text',
          type: 'richText'
        }
      ]
    },
    {
      name: 'carouselImagesForNonAuthenticatedUser',
      defaultValue: [],
      type: 'list',
      subFields: [
        {
          name: 'image',
          type: 'file'
        }
      ]
    },
    { name: 'horizontallyReversed', type: 'boolean', defaultValue: false },
    { name: 'hideIngredientsList', type: 'boolean', defaultValue: false },
    { name: 'hideTags', type: 'boolean', defaultValue: false },
    {
      name: 'customTags',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'tag',
          type: 'text'
        }
      ]
    },
    {
      name: 'hideWhen',
      type: 'text',
      enum: [NEVER, USER_IS_AUTHENTICATED, USER_IS_NOT_AUTHENTICATED],
      defaultValue: NEVER
    },
    {
      name: 'forceOneTimeOrSubscription',
      type: 'text',
      enum: [SHOW_RADIO_BUTTON, FORCE_ONE_TIME, FORCE_SUBSCRIPTION],
      defaultValue: SHOW_RADIO_BUTTON
    },
    { name: 'buttonTextForAuthenticatedUser', type: 'text', defaultValue: 'GET MY FORMULA' },
    { name: 'buttonTextForUnauthenticatedUser', type: 'text', defaultValue: 'GET MY FORMULA' },
    {
      name: 'legendBelowButtonOnlyOneTime',
      type: 'text',
      defaultValue: 'Try it à la carte. Free shipping.'
    },
    { name: 'addPriceInButtonWhenIsAuthenticated', type: 'boolean', defaultValue: false },
    { name: 'yotpo_product', type: 'reference', model: 'yotpo-product' }
  ]
})
