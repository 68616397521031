import React from 'react'
import { Builder } from '@builder.io/react'
import { builderConfig } from './Button'
import ProvenButtonAddToCart from '../../../shared/proven-button-add-to-cart'

import { useBuilderSharedData } from 'hooks/useBuilderSharedData'

import {
  SYSTEM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  CLEANSER_PRODUCT,
  EYE_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  DAY_EYE_CREAM_PRODUCT,
  SERUM_PRODUCT
} from '../../../../constants/products'

const ButtonAddToCart = ({
  color,
  variant,
  forceSubscriptionAction,
  authenticatedText: authenticatedTextFromBuilder,
  unauthenticatedText: unauthenticatedTextFromBuilder,
  textToShowForAddingToSubscription,
  productId,
  addSavingCancellingAndShippingLegend,
  addPriceWhenIsAuthenticated,
  intent,
  fullWidth
}) => {
  const { reactOnly } = useBuilderSharedData()

  const authenticatedText =
    authenticatedTextFromBuilder || reactOnly?.pdp?.defaultAuthenticatedButtonText
  const unauthenticatedText =
    unauthenticatedTextFromBuilder || reactOnly?.pdp?.defaultUnauthenticatedButtonText

  return (
    <ProvenButtonAddToCart
      color={color}
      variant={variant}
      forceSubscriptionAction={forceSubscriptionAction}
      textToShowForAuthenticated={authenticatedText}
      textToShowForAddingToSubscription={textToShowForAddingToSubscription}
      textToShowForUnauthenticated={unauthenticatedText}
      addPriceWhenIsAuthenticated={addPriceWhenIsAuthenticated}
      productId={productId}
      addSavingCancellingAndShippingLegend={addSavingCancellingAndShippingLegend}
      intent={intent}
      fullWidth={fullWidth}
    />
  )
}

Builder.registerComponent(ButtonAddToCart, {
  ...builderConfig,
  name: 'Button Add to Cart',
  inputs: [
    ...builderConfig.inputs.filter(({ name }) => name !== 'text'),
    { name: 'authenticatedText', type: 'text', defaultValue: 'Add to cart' },
    { name: 'unauthenticatedText', type: 'text', defaultValue: 'Get my formula' },
    { name: 'textToShowForAddingToSubscription', type: 'text' },
    {
      name: 'productId',
      type: 'text',
      required: false,
      helperText: `if this field is not passed, productId will be determined by either intent field or context intent`,
      enum: [
        SYSTEM_PRODUCT,
        DAY_MOISTURIZER_PRODUCT,
        NIGHT_CREAM_PRODUCT,
        CLEANSER_PRODUCT,
        EYE_CREAM_PRODUCT,
        NIGHT_EYE_CREAM_PRODUCT,
        DAY_EYE_CREAM_PRODUCT,
        SERUM_PRODUCT
      ]
    },
    {
      name: 'addPriceWhenIsAuthenticated',
      type: 'boolean',
      defaultValue: false,
      helperText: `Add the corresponding price at the end of the button's text, only when user is authenticated`
    },
    {
      name: 'forceSubscriptionAction',
      type: 'boolean',
      defaultValue: false,
      helperText: `If it's true, it will always try to add a subscription to cart/current subscription`
    },
    {
      name: 'addSavingCancellingAndShippingLegend',
      type: 'boolean',
      defaultValue: false,
      helperText: `If it's it add a legend below the button that includes Subscribe & save $xx and cancel anytime, free shipping`
    },
    { name: 'intent', type: 'text', enum: ['skin', 'eye'] }
  ]
})
